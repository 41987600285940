var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        closable: true,
        visible: _vm.visible,
        size: "normal",
        title: (_vm.mode == "add" ? "新建" : "编辑") + "岗位分类",
        "mask-closable": false,
        "ok-text": "保存",
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "分类名称", prop: "postTypeName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入1-10个字符" },
                model: {
                  value: _vm.form.postTypeName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "postTypeName", $$v)
                  },
                  expression: "form.postTypeName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "分类说明", prop: "detail" } },
            [
              _c("a-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入0-200个字符",
                  "auto-size": { minRows: 3, maxRows: 7 },
                },
                model: {
                  value: _vm.form.detail,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "detail", $$v)
                  },
                  expression: "form.detail",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }